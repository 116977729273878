import React from "react";

const Footer = () => (
  <footer className="relative mt-20 pb-8 bg-gray-900 text-green-200">
    <div className="flex justify-center items-center py-4 bg-gray-800 text-white font-medium">
      <a
        href="https://noirlove.com/terms-of-services"
        className="px-8 border-r border-white"
      >
        Terms of Service
      </a>
      <a href="https://noirlove.com/privacy-policy" className="px-8">
        Privacy Policy
      </a>
    </div>
    <p className="mt-8 text-center leading-loose tracking-wide">
      <span className="font-bold text-lg uppercase">Contact US</span>
      <br />
      <a className="font-semibold" href="mailto:info@noirlove.com">
        info@noirlove.com
      </a>
    </p>
    <p className="mt-8 text-center px-2 text-xs text-gray-600 font-thin tracking-wider">
      Copyright © 2020 NOIRLOVE. All rights reserved.
    </p>
  </footer>
);

export default Footer;
